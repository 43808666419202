<template>
  <section class="py-5">
    <div class="loaderss" v-if="showLoading" />
    <div class="container">
      <form method="post" @submit.prevent>
        <h2>Checkout Your Order</h2>
        <div id="braintree-checkout-drop-in" />
        <button
          ref="checkoutButton"
          type="submit"
          class="btn shadow bg-yellow text-capitalize text-white"
          role="button"
        >
          Checkout
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import checkout from "@/services/checkout";
import { decryptAES } from "@/utils/crypto";

export default {
  name: "BraintreeCheckout",
  data() {
    return {
      showLoading: false,
    };
  },
  mounted() {
    this.createDropIn();
  },
  methods: {
    createDropIn() {
      this.showLoading = true;
      const vm = this;
      const router = this.$router;
      const notify = this.$notify;
      const { checkoutButton } = this.$refs;
      const tokenId = JSON.parse(localStorage.getItem("TokenId"));

      this.$dropIn.create(
        {
          authorization: tokenId,
          selector: "#braintree-checkout-drop-in",
        },
        (err, dropinInstance) => {
          vm.showLoading = false;
          if (err) {
            vm.showLoading = false;
            return notify.error({
              title: "Oops",
              message: "Something went wrong",
            });
          }

          checkoutButton.addEventListener("click", () => {
            vm.showLoading = true;
            dropinInstance.requestPaymentMethod(async (err, payload) => {
              if (err) {
                vm.showLoading = false;
                return notify.error({
                  title: "Oops",
                  message: "Something went wrong!",
                });
              }

              const response = await checkout.createPayment({
                orderId: Number(decryptAES(localStorage.getItem("OrderId"))),
                customerId: Number(
                  decryptAES(localStorage.getItem("CustomerId"))
                ),
                Nonce: payload.nonce,
                Session: JSON.parse(localStorage.getItem("TokenId")),
                Cusip: JSON.parse(localStorage.getItem("SessionExpireToken")),
              });

              if (response && response.data.isSuccess) {
                localStorage.removeItem("TokenId");
                localStorage.setItem(
                  "OrderResponse",
                  JSON.stringify(response.data)
                );
                vm.$store.commit("setTotalOrder");
                localStorage.removeItem("SessionExpireToken");
                localStorage.setItem("paymentStatus", true);
                router.push("Orders");
              } else {
                localStorage.setItem("paymentStatus", false);
                localStorage.removeItem("TokenId");
                localStorage.removeItem("SessionExpireToken");
                router.push("/");
              }

              vm.showLoading = false; // hide loading spinner
              // return router.push("Orders"); // push to orders page
            });
          });
        }
      );
    },
  },
};
</script>

<style scoped>
#braintree-checkout-drop-in {
  min-height: 12rem;
}
</style>
